import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar, Link, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import { BrandContext } from '../../App';
import AccountCircle from '@mui/icons-material/AccountCircle';
import navbg from '../../assets/GNBackground.png'

export function TopNav(props) {
    const { user, authToken } = React.useContext(BrandContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const navigate = useNavigate();

    function handleAppBarMenu(e) {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getLinks(LinkElement = Link) {
        if (authToken) {
            return (
                <LinkElement href={`/dashboard/home`} sx={{ mr: 3, color: 'white' }}>My Dashboard</LinkElement>
            );
        }

        if (props.minimal === true) {
            return;
        }

        // Default, not logged in
        return (
            [
                <LinkElement key={1} href="/dashboard/home" sx={{ mr: 3, color: 'white' }}>Dashboard</LinkElement>,
            ]
        );
    }

    return (
        <AppBar position="static" elevation={0} sx={{ pt: 1, pb: 1, background: `url(${navbg})`, backgroundSize: 'cover' }}>
            <Toolbar>
                <Box sx={{ width: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography variant="caption" color="white" component="div" sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
                        {getLinks()}
                    </Typography>
                    <RouterLink to={authToken ? '/dashboard/home' : `/login?next=${window.location.pathname}`}>
                        <IconButton
                            size="large"
                            color="secondary"
                            edge="end">
                            <AccountCircle />
                        </IconButton>
                    </RouterLink>
                </Box>
                <Box sx={{ width: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <IconButton
                        size="large"
                        onClick={handleAppBarMenu}
                        edge="end">
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="login-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {getLinks(MenuItem)}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

