import { Room, RoomEvent, ConnectionState, AudioPresets, createLocalAudioTrack } from 'livekit-client';

export class LiveKitManager {
  constructor(trackHandler) {
    this.room = new Room({
      adaptiveStream: true,
      dynacast: true,
      audioPresets: AudioPresets.speech,
    });
    this.trackHandler = trackHandler;
    this.audioElements = new Map();
    this.isConnecting = false;
  }

  async connect(url, { token }) {

    if (this.isConnecting) {
      console.warn('Already connecting to a room, ignoring this request');
      return false;
    }

    if (this.isConnected()) {
      console.log('Disconnecting from current room before connecting to a new one');
      this.cleanup();
    }

    this.isConnecting = true;

    try {
      this.setupEventListeners();
      console.log('Connecting to LiveKit with token:', token.substring(0, 20) + '...');

      await this.room.connect(url, token);
      console.log('Connected to room:', this.room.name);

      if (this.room.participants) {
        this.room.participants.forEach(participant => {
          this.handleParticipantConnected(participant);
        });
      }

      try {
        await this.setMicrophoneEnabled(true);
      } catch (error) {
        console.error('Error enabling microphone', error);
      }
      return true;

    } catch (error) {
      console.error('Error connecting to room:', error);
      throw error;
    } finally {
      this.isConnecting = false;
    }
  }

  setupEventListeners() {
    this.room.on(RoomEvent.Disconnected, (reason) => {
      console.log('Disconnected:', reason);
    });

    this.room.on(RoomEvent.ConnectionStateChanged, (state) => {
      console.log('Connection state changed:', state);
      if (state === ConnectionState.Disconnected) {
        console.log('Connection state changed to disconnected');
      }
    });

    this.room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
      console.log('Track subscribed:', track.kind);
      if (track.kind === 'audio') {
        this.handleAudioTrackSubscribed(track, participant);
      }
      if (track.kind === 'audio' || track.kind === 'video') {
        this.trackHandler({ track, streams: [track.mediaStream], participant });
      }
    });

    this.room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
      console.log('Track unsubscribed:', track.kind);
      if (track.kind === 'audio') {
        this.handleAudioTrackUnsubscribed(track, participant);
      }
    });
  }

  handleParticipantConnected(participant) {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track;
        if (track) {
          const streams = [track.mediaStream];
          this.trackHandler({ track, streams, participant });
          if (track.kind === 'audio') {
            this.handleAudioTrackSubscribed(track, participant);
          }
        }
      }
    });
  }

  handleAudioTrackSubscribed(track, participant) {
    const audioElement = document.createElement('audio');
    audioElement.srcObject = new MediaStream([track.mediaStreamTrack]);
    audioElement.autoplay = true;
    audioElement.controls = true;
    document.body.appendChild(audioElement);
    this.audioElements.set(track.sid, audioElement);
  }

  handleAudioTrackUnsubscribed(track, participant) {
    const audioElement = this.audioElements.get(track.sid);
    if (audioElement) {
      audioElement.srcObject = null;
      document.body.removeChild(audioElement);
      this.audioElements.delete(track.sid);
    }
  }

  async setMicrophoneEnabled(enabled) {
    if (this.room && this.room.localParticipant) {
      if (enabled) {
        const audioTrack = await createLocalAudioTrack();
        this.room.localParticipant.publishTrack(audioTrack);
      } else {
        this.room.localParticipant.audioTracks.forEach(publication => {
          this.room.localParticipant.unpublishTrack(publication.track);
        });
      }
      return true;
    }
    return false;
  }

  isConnected() {
    return this.room && this.room.state === ConnectionState.Connected;
  }

  cleanup() {
    if (this.isConnected()) {
        console.log('Cleanup..');
        try {
            this.room.disconnect();
        } catch (error) {
            console.error("Error disconnecting room:", error);
        }

        // clean state
        this.room = new Room({
            adaptiveStream: true,
            dynacast: true,
            audioPresets: AudioPresets.speech,
        });
    }

    this.audioElements.forEach((audioElement) => {
        try {
            audioElement.srcObject = null;
            if (audioElement.parentNode) {
                audioElement.parentNode.removeChild(audioElement);
            }
        } catch (error) {
            console.error("Error cleaning up audio element:", error);
        }
    });
    this.audioElements.clear();
    this.isConnecting = false;
  }
}