import * as React from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { Typography, Button, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Stack, Paper, Alert, Box, Link } from '@mui/material';
import { BrandContext } from '../App';
import heropic from '../assets/man_with_laptop.png'
import { LandingLayout } from '../components/complex/LandingLayout';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function LoginPage(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordResetLink, setShowPasswordResetLink] = React.useState(false);
    const [loginCredentials, setLoginCredentials] = React.useState({});
    const [loginErrorMessage, setLoginErrorMessage] = React.useState("");
    const [validInputs, setValidInputs] = React.useState(false);
    const { brand, authToken, saveAuthToken } = React.useContext(BrandContext);

    const navigate = useNavigate();
    const [qParams] = useSearchParams();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleLogin = (e) => {

        e.preventDefault();

        if (!validInputs) {
            setLoginErrorMessage("Sorry, we couldn't log you in with these credentials.");
        }

        fetch(`${brand.api_base_url}/api-token-auth/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginCredentials)
        })
            .then((response) => response.json())
            .then((data) => {
                if (Object.keys(data).includes('token')) {
                    // success
                    saveAuthToken(data['token']);

                } else {
                    setLoginErrorMessage("Sorry, we couldn't log you in with these credentials.");
                    setShowPasswordResetLink(true);
                }
            }).then()
            .catch((err) => {
                console.log(err.message);
                setLoginErrorMessage(err.message);
            });


    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLoginCredentialChange = (e) => {
        setLoginCredentials({ ...loginCredentials, [e.target.name]: e.target.value });
    };

    const requestPasswordReset = () => {
        fetch(`${brand.api_base_url}/request_password_reset`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': loginCredentials['username'] })
        });

        setShowPasswordResetLink(false);
        setLoginErrorMessage("We have sent a password reset link to this e-mail address.");

    };

    React.useEffect(() => {
        if (authToken.length > 0) {
            if (qParams.has('next')) {
                navigate(qParams.get('next'));
            } else {
                navigate("/dashboard/home");
            }

        }
    }, [authToken]);

    React.useEffect(() => {
        if (Object.keys(loginCredentials).includes('username') &&
            Object.keys(loginCredentials).includes('password') &&
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(loginCredentials['username']) &&
            /.{1,}/.test(loginCredentials['password'])) {
            setValidInputs(true);
        } else {
            setValidInputs(false);
        }

    }, [loginCredentials]);

    return (
        <LandingLayout>

            <Paper sx={{ maxWidth: { xs: 0.95, lg: 0.4 }, m: 1, mt: 5, p: 3, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#fefefe', display: 'flex', justifyContent: 'space-evenly' }}>
                <Stack direction="column" sx={{ textAlign: 'left', display: 'flex', justifyContent: 'left', minWidth: '300px' }}>
                    <Typography variant="h3" gutterBottom>Log in</Typography>
                    {qParams.has('after') ? (<Alert severity="success" sx={{ mt: 2 }}>Your password was changed successfully. Please log in with your new password.</Alert>) : (<Typography variant="body2">No {brand.name} account yet? <Link href="/signup">Sign up</Link></Typography>)}
                    <form onSubmit={handleLogin}>
                        <FormControl sx={{ mt: 4, width: 1 }}>
                            <TextField
                                required
                                name="username"
                                label="E-mail"
                                variant="outlined"
                                onChange={handleLoginCredentialChange}
                            />
                        </FormControl>
                        <FormControl sx={{ mt: 2, width: 1 }}>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleLoginCredentialChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"

                            />
                        </FormControl>
                        {loginErrorMessage.length > 0 && (<><Alert severity="error" sx={{ mt: 2 }}>{loginErrorMessage} {showPasswordResetLink && (<Link onClick={requestPasswordReset} href="#">Forgot Password?</Link>)}</Alert></>)}
                        <Button type="submit" sx={{ mt: 4, height: '3rem' }} variant="contained" disabled={!validInputs} onClick={handleLogin}>Continue</Button>
                    </form>
                </Stack>
                <Box component="img" src={heropic} sx={{ height: '300px', display: { xs: 'none', xl: 'block' } }} />
            </Paper>
        </LandingLayout>
    );
}
