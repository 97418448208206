import React from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Paper,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { BrandContext } from '../App';
import { getSignupSteps } from '../components/complex/SignupSteps';
import { LandingLayout } from '../components/complex/LandingLayout';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const signupValueRegex = [
  {
    'address': /^(www\.)?[-a-zA-Z0-9]{1,256}\.[a-zA-Z0-9]{2,6}\b([-a-zA-Z0-9():%_\+~#?&//=]*)$/,
  },
  {
    'address': /^(www\.)?[-a-zA-Z0-9]{1,256}\.[a-zA-Z0-9]{2,6}\b([-a-zA-Z0-9():%_\+~#?&//=]*)$/,
    'email': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }
];

export default function Signup() {
  const { brand } = React.useContext(BrandContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([]);
  const [checkedAddress, setCheckedAddress] = React.useState(null);
  const [checkedAddressStatus, setCheckedAddressStatus] = React.useState('pending');

  const [signupValues, setSignupValues] = React.useState({});
  const [signupValueErrors, setSignupValueErrors] = React.useState([]);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();


  React.useEffect(() => {
    document.title = `Get started with ${brand.name}`;
    setSteps(getSignupSteps(brand));
  }, [brand]);

  React.useEffect(() => {
    if (searchParams.get('address')) {
      setSignupValues({ 'address': searchParams.get('address') });
      setActiveStep(1);

    }
  }, [searchParams]);

  function getSignupValueErrors() {
    var errors = [];

    if (!signupValueRegex[activeStep]) {
      return [];
    }

    for (const [name, regex] of Object.entries(signupValueRegex[activeStep])) {
      // Add into list of errors if the field isn't filled in or fails the regex test
      if (!Object.keys(signupValues).includes(name) || !regex.test(signupValues[name])) {
        errors.push(name);
      }
    }
    return errors;
  }

  React.useEffect(() => {

    // Check address
    if (activeStep === 1 && checkedAddress != signupValues['address']) {
      setCheckedAddress(signupValues['address']);

      fetch(`${brand.api_base_url}/check_url`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...signupValues })
      })
        .then((response) => response.json())
        .then((data) => {
          if (Object.keys(data).includes('status')) {
            setCheckedAddressStatus(data['status']);
          } else {
            setCheckedAddressStatus('error');
          }
        }).then()
        .catch((err) => {
          setCheckedAddressStatus('error');
        });
    }

  }, [activeStep]);

  React.useEffect(() => {
    const errors = getSignupValueErrors();
    setSignupValueErrors(errors);

    if (errors.includes('address') && activeStep === 1) {
      setActiveStep(0);
    }

    // Handle submit
    if (errors.length === 0 && activeStep === steps.length) {
      fetch(`${brand.api_base_url}/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...signupValues })
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('success');
          navigate('/info/signup-completed');
        }).then()
        .catch((err) => {
          console.log(err.message);
        });
    }

  }, [signupValues, activeStep]);

  const handleNext = () => {
    if (signupValueErrors.length == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getFormTitle = () => {
    return `Get started with ${brand.name}`;
  };

  return (
    <LandingLayout>
      <Grid container spacing={0} className="signupGridContainer">
        <Grid item xs={0} lg={3}></Grid>
        <Grid item xs={12} lg={6}>
          {/* header */}
          <Typography variant="h4" color="white" sx={{ mt: 4, ml: 1, mb: 2, textAlign: 'center' }}>{getFormTitle()}</Typography>
        </Grid>
        <Grid item xs={0} lg={3}></Grid>
        <Grid item xs={0} lg={3}></Grid>
        <Grid item xs={12} lg={6}>
          <Paper elevation={1} sx={{ m: { xs: 1, md: 0 }, p: 4 }}>

            {activeStep === steps.length ? (
              <React.Fragment>
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                  <Typography sx={{ mt: 2, mb: 1 }} variant="h4">Submitting your details, please wait ...</Typography>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ m: 4, textAlign: 'center' }}>{(steps.length > 0) && getSignupSteps(brand, signupValues, setSignupValues, signupValueErrors, checkedAddressStatus)[activeStep]['content']}</Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, display: activeStep === 0 ? 'none' : 'inline' }}
                    variant="contained"
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={handleNext} color="secondary" variant="contained" endIcon={<ArrowForwardIcon />} disabled={Object.keys(signupValues).length === 0 || signupValueErrors.length > 0}>
                    {activeStep === steps.length - 1 ? 'Finish' : `Next: ${steps[activeStep + 1].title}`}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            <Box sx={{ mt: 3 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((s, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  const label = s.title;

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
                <Step>
                  <StepLabel>You're done!</StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={0} lg={3}></Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', pt: 2 }}>
          {/* footer */}
          <Typography variant="caption" color="white"></Typography>
        </Grid>
      </Grid>
    </LandingLayout>
  );
}
