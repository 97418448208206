import React from 'react';
import {
    Button,
    Typography,
    Paper,
    Snackbar,
    Card,
    CardMedia,
    CardContent,
    CardActions,

} from '@mui/material';
import { BrandContext } from '../../App';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

export default function DashboardTrainingPrograms() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [trainingPrograms, setTrainingPrograms] = React.useState([]);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const timeAgo = new TimeAgo('en-US');


    function updateTrainingPrograms() {
        fetch(`${brand.api_base_url}/trainingprograms`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setTrainingPrograms(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Programs - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateTrainingPrograms();

    }, [authToken]);

    function get_num_scenarios(path) {
        var scen_num = 0;

        path.steps.forEach((step) => {
            if (step.chapter != null) {
                scen_num = scen_num + 1;
            } else {
                scen_num = scen_num + step.scenarios?.length
            }
        });

        return scen_num;
    }

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarMessage}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            <Typography variant="h4">My Programs</Typography>
            <Paper sx={{ m: 3, p: 2, display: 'flex' }}>
                {trainingPrograms.length > 0 && trainingPrograms.map((tprogram, index) => {
                    return (
                        <>
                            <Card sx={{ maxWidth: 345, m: 1 }} key={index}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={tprogram.image}
                                    title=""
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {tprogram.name}
                                    </Typography>
                                        {tprogram.prerequisites && tprogram.prerequisites.length > 0 && (
                                                <Typography variant="body2" color="text.secondary">{tprogram.prerequisites.length} certification required to unlock this learning path</Typography>
                                        )}

                                        {tprogram.leads_to && (
                                                <Typography variant="body2" color="text.secondary">{get_num_scenarios(tprogram)} chapter{get_num_scenarios(tprogram) === 1 ? '' : 's'} to complete for the <b>{tprogram.leads_to.name}</b> certification</Typography>
                                        )}
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {tprogram.prerequisites && tprogram.prerequisites.length > 0 ? (
                                        <Button size="small" href={`/dashboard/trainingprogram/${tprogram.id}`} variant="outlined" disabled>
                                            Read more
                                        </Button>
                                    ) : (
                                        <Button size="small" href={`/dashboard/trainingprogram/${tprogram.id}`} variant="outlined">
                                            Read more
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </>
                    );
                })}
            </Paper>

        </React.Fragment>
    );
}