import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { BrandContext } from '../../App';
import landingbg from '../../assets/GNBackground.png'

import { TopNav } from './TopNav';

export function LandingLayout(props) {
    const { brand } = React.useContext(BrandContext);

    return (
        <Box
            sx={{
                width: 1,
                minHeight: '100vh',
                background: `url(${landingbg})`
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <TopNav minimal={props.topnavminimal} />
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    {props.children}
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>

                </Grid>
            </Grid>
        </Box>
    );
}
