import React, { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Button, Typography, Card, CardContent, Box, Collapse, IconButton,
    Link
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BrandContext } from '../../App';

import VerifiedIcon from '@mui/icons-material/Verified';

export default function DashboardProgram() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [trainingProgram, setTrainingProgram] = React.useState(null);
    const [expandedStates, setExpandedStates] = useState({});

    const { trainingprogram_id } = useParams();

    function updateLearningPathData() {
        fetch(`${brand.api_base_url}/trainingprograms?trainingprogram_id=${trainingprogram_id}`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setTrainingProgram(data);
                console.log(data);
                document.title = `${data.name} - ${brand.name}`;
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    const handleExpandClick = (id) => {
        setExpandedStates((prev) => {
            const newState = { ...prev, [id]: !prev[id] };
            return newState;
        });
    };

    React.useEffect(() => {
        updateLearningPathData();
    }, [authToken]);

    return (
        <React.Fragment>
            {trainingProgram != null && (
                <>
                    <Button href="/dashboard/trainingprograms" sx={{ mb: 2 }}>Back to all your Programs</Button>
                    <Typography variant="h4">{trainingProgram.name}</Typography>

                    {trainingProgram.steps &&
                        trainingProgram.steps.length > 0 &&
                        trainingProgram.steps.map((stepChapter) => {
                            // Calculate whether all scenarios are completed
                            const allScenariosCompleted =
                                stepChapter.chapter.steps &&
                                stepChapter.chapter.steps.every(
                                    (stepScenario) => stepScenario.scenario.completed === true
                                );

                            // Calculate the percentage of completed scenarios
                            const totalScenarios = stepChapter.chapter.steps?.length || 0;
                            const completedScenarios = stepChapter.chapter.steps?.filter(
                                (stepScenario) => stepScenario.scenario.completed === true
                            ).length || 0;
                            const percentageCompleted = totalScenarios > 0
                                ? Math.round((completedScenarios / totalScenarios) * 100)
                                : 0;

                            return (
                                <Card key={stepChapter.chapter.id} sx={{ position: 'relative', display: 'flex', m: 1, flexDirection: 'row', width: '100%' }}>
                                    <IconButton
                                        onClick={() => handleExpandClick(stepChapter.chapter.id)}
                                        aria-expanded={!!expandedStates[stepChapter.chapter.id]}
                                        aria-label="show more"
                                        sx={{ margin: '1rem 0', position: 'absolute' }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "0.25rem 0.5rem",
                                            border: allScenariosCompleted ? "1px solid #a0d7a5" : "1px solid #ccc",
                                            borderRadius: "16px",
                                            backgroundColor: allScenariosCompleted ? "#e6f7e8" : "#f5f5f5",
                                            color: allScenariosCompleted ? "#388e3c" : "#757575",
                                            fontWeight: "bold",
                                            fontSize: "0.875rem", // Small font size
                                            gap: "0.4rem", // Space between icon and text
                                            justifyContent: "center",
                                            position: "absolute",
                                            right: "1rem",
                                            margin: '1.4rem 0',
                                        }}
                                    >
                                        {allScenariosCompleted ? (
                                            <>
                                                <VerifiedIcon sx={{ color: "green", fontSize: "1rem" }} />
                                                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                                                    Completed
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography variant="body2" sx={{ fontWeight: "500" }}>
                                                {`${percentageCompleted}% Completed`}
                                            </Typography>
                                        )}
                                    </Box>
                                    <CardContent sx={{ flex: '1 0 auto', margin: '0.25rem 2rem', width: '100%' }}>
                                        <Typography variant="h5" sx={{ mb: 2, cursor: 'pointer' }} onClick={() => handleExpandClick(stepChapter.chapter.id)}>{stepChapter.chapter.name}</Typography>
                                        <Collapse
                                            in={!!expandedStates[stepChapter.chapter.id]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            {stepChapter.chapter.steps &&
                                                stepChapter.chapter.steps.length > 0 &&
                                                stepChapter.chapter.steps.map((stepScenario) => (
                                                    <Link href={`/dashboard/simulate/${stepScenario.scenario.id}`} sx={{ color: 'black', textDecoration: 'none' }}>
                                                        <Box
                                                            key={stepScenario.scenario.id}
                                                            sx={{
                                                                padding: '0.5rem',
                                                                display: 'flex',
                                                                width: '90%',
                                                                borderTop: 0.25,
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                            <Typography variant="h6">{stepScenario.scenario.name}</Typography>
                                                            {stepScenario.scenario.completed !== true ? (
                                                                <ChevronRightIcon />
                                                            ) : (
                                                                <VerifiedIcon sx={{ color: 'green' }} />
                                                            )}
                                                        </Box>
                                                    </Link>
                                                ))}
                                        </Collapse>
                                    </CardContent>
                                </Card>
                            );
                        })}
                </>
            )}
        </React.Fragment>
    );
}