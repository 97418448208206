import React, { useEffect, useState, useContext } from "react";
import { LiveKitManager } from "../components/complex/LiveKitManager";
import { BrandContext } from "../App";
import { Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";

const App = ({ scenarioId = null }) => {
    const { brand, authToken } = useContext(BrandContext);
    const [roomName, setRoomName] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [error, setError] = useState(null);
    const [liveKitManager, setLiveKitManager] = useState(null);
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [simulationId, setSimulationId] = useState(null);

    const params = useParams();
    const urlScenarioId = params.scenario_id;
    const effectiveScenarioId = scenarioId || urlScenarioId;

    // Track handler function
    const trackHandler = ({ track, streams, participant }) => {
        console.log('Track handler:', track, streams, participant);
    };

    // Create manager once on component mount
    useEffect(() => {
        const manager = new LiveKitManager(trackHandler);
        setLiveKitManager(manager);

        return () => {
            console.log("Cleaning up LiveKitManager on unmount");
            manager.cleanup();
        };
    }, []);

    // connect when component mounts and liveKitManager is ready
    useEffect(() => {
        if (liveKitManager && effectiveScenarioId && !isConnected && !isConnecting) {
            connectToLiveKit();
        }
    }, [liveKitManager, effectiveScenarioId]);

    // connection function
    const connectToLiveKit = async () => {
        if (!liveKitManager || isConnecting || !effectiveScenarioId) return;

        setIsConnecting(true);
        setError(null);

        console.log("Connecting with scenario ID:", effectiveScenarioId);

        try {
            const requestBody = { 'scenario_id': effectiveScenarioId };
            console.log("Request body:", requestBody);

            const response = await fetch(`${brand.api_base_url}/start_simulation`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("API error response:", errorText);
                throw new Error(`Failed to fetch token: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            console.log("API response data:", data);

            if (!data['token'] || !data['livekit_url']) {
                throw new Error("No token or URL received from server");
            }

            setSimulationId(data.id);
            setRoomName(data.id || "LiveKit Room");
          
            await liveKitManager.connect(data['livekit_url'], { token: data['token'] });
          
            setIsConnected(true);

            // enable audio when connected
            await handleEnableAudio();

        } catch (error) {
            console.error("Connection error:", error);
            setError(error.message);
            setSnackbarMessage('Connection to LiveKit failed: ' + error.message);
        } finally {
            setIsConnecting(false);
        }
    };

    // if using manual connection if needed
    const handleConnect = () => connectToLiveKit();

    const handleDisconnect = () => {
        if (liveKitManager) {
            liveKitManager.cleanup();
            setIsConnected(false);
            setAudioEnabled(false);
        }
    };

    // ending the simulation
    const handleEndSimulation = async () => {
        if (!simulationId) {
            setSnackbarMessage('No active simulation to end');
            return;
        }

        try {
            // endpoint from django
            const response = await fetch(`${brand.api_base_url}/rtc_end_simulation`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'simulation_id': simulationId
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Error ending simulation:", errorText);
                throw new Error(`Failed to end simulation: ${response.status} ${response.statusText}`);
            }

            handleDisconnect();
            setSnackbarMessage('Simulation ended successfully');
            setSimulationId(null);
            window.location = '/dashboard/simulations';

        } catch (error) {
            console.error("Error ending simulation:", error);
            setSnackbarMessage(`Failed to end simulation: ${error.message}`);
        }
    };

    const handleEnableAudio = async () => {
        if (liveKitManager) {
            try {
                await liveKitManager.setMicrophoneEnabled(true);
                setAudioEnabled(true);
            } catch (error) {
                console.error('Error enabling microphone:', error);
                setError('Failed to enable audio: ' + error.message);
            }
        }
    };

    return (
        <div className="container">
            <Snackbar
                open={snackbarMessage ? true : false}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            <h1>LiveKit Simulation</h1>
            {error && <p className="error">Error: {error}</p>}

            {isConnecting && (
                <div className="connecting-message">
                    <p>Connecting to simulation...</p>
                </div>
            )}

            {!isConnected && !isConnecting && (
                <div className="connection-controls">
                    <button
                        onClick={handleConnect}
                        disabled={!liveKitManager || !effectiveScenarioId}
                        className="connect-btn"
                    >
                        Connect to LiveKit
                    </button>
                </div>
            )}

            {isConnected && (
                <div className="video-container">
                    <p>Connected to simulation: {roomName}</p>

                    <div className="controls">
                        <button
                            onClick={handleDisconnect}
                            className="disconnect-btn"
                            style={{
                                marginRight: '10px',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Leave Room
                        </button>

                        <button
                            onClick={handleEndSimulation}
                            className="end-simulation-btn"
                            style={{
                                backgroundColor: '#f44336',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            End Simulation
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;