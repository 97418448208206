import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { LiveKitManager } from "./LiveKitManager";
import { BrandContext } from "../../App";
import { Box, Button, Typography, CircularProgress, Paper, IconButton, Collapse, Snackbar, Avatar } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { UnityManager } from '../../services/unity/UnityManager';
import { UnityAudioAnalyzer } from '../../services/unity/UnityAudioAnalyzer';
import { MediaManager } from '../../services/media/MediaManager';

export const CallScreen = ({ scenarioId = null, scenarioData = null, isAudio = false, user = null }) => {
  const { brand, authToken } = useContext(BrandContext);
  const [roomName, setRoomName] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);
  const [imgSrc, setImgSrc] = useState(scenarioData.image);

  const [liveKitManager, setLiveKitManager] = useState(null);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [simulationId, setSimulationId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [showUnityCanvas, setShowUnityCanvas] = useState(true); // default to true for auto-loading
  const [currentMessage, setCurrentMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [unityLoaded, setUnityLoaded] = useState(false);
  const [avatarLoaded, setAvatarLoaded] = useState(false);

  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const transcriptRef = useRef(null);
  const unityInstance = useRef(null);
  const originalConsoleLog = useRef(window.console.log);

  const [videoPosition, setVideoPosition] = useState({ x: 20, y: 20 });
  const [isDragging, setIsDragging] = useState(false);

  const params = useParams();
  const urlScenarioId = params.scenario_id;
  const effectiveScenarioId = scenarioId || urlScenarioId;

  const [unityManager, setUnityManager] = useState(null);
  const [audioAnalyzer, setAudioAnalyzer] = useState(null);
  const [mediaManager, setMediaManager] = useState(null);
  const media = new MediaManager();

  function getInitials(user) {
    if (!user) return 'Guest';
    return `${user.first_name?.[0] ?? ''}${user.last_name?.[0] ?? ''}`.toUpperCase();
  }

  useEffect(() => {
    originalConsoleLog.current = window.console.log;
    window.console.log = function (message) {
      originalConsoleLog.current.apply(console, arguments);
      const logString = Array.from(arguments).join(' ');
      if ((typeof message === 'string' && (message.includes("Initialised camFollow and speechController"))) ||
        (logString.includes("avatar") && logString.includes("loaded"))) {
        originalConsoleLog.current("[Hook] Unity avatar fully loaded.");
        setAvatarLoaded(true);
      }

      if (logString.includes("Track subscribed: audio")) {
        originalConsoleLog.current("[Hook] Audio track subscribed.");
        setIsSpeaking(true);
      }
    };

    return () => {
      window.console.log = originalConsoleLog.current;
    };
  }, []);

  useEffect(() => {
    const unity = new UnityManager(canvasRef);
    const analyzer = new UnityAudioAnalyzer(unity);

    setUnityManager(unity);
    setAudioAnalyzer(analyzer);
    setMediaManager(media);

    const manager = new LiveKitManager(trackHandler);
    setLiveKitManager(manager);

    // tiemaout to ensure the DOM is ready
    if (!isAudio) {
      setTimeout(() => {
        initializeUnity();
      }, 500);
    }

    // cleanup on unmount
    return () => {
      if (analyzer) analyzer.stop();
      if (unity) unity.forceCleanup();
      if (media) media.cleanup();

      // liveKit cleanup
      manager.cleanup();
      const remoteAudio = document.getElementById('remote-audio');
      if (remoteAudio) remoteAudio.remove();
    };
  }, []);

  // connect to LiveKit when avatar is loaded
  useEffect(() => {
    if (avatarLoaded && liveKitManager && effectiveScenarioId && !isConnected && !isConnecting) {
      console.log("==> Avatar loaded: connecting to LiveKit");
      connectToLiveKit();
    }
  }, [avatarLoaded, effectiveScenarioId, isConnected, isConnecting]);

  useEffect(() => {
    if (isAudio && liveKitManager) {
      console.log("==> Audio only: connecting to LiveKit");
      connectToLiveKit();
    }
  }, [liveKitManager]);

  useEffect(() => {
    if (unityLoaded && !avatarLoaded) {
      const timeoutId = setTimeout(() => {
        if (!avatarLoaded) {
          console.log("==> Avatar load timeout - forcing connection");
          setAvatarLoaded(true);
        }
      }, 60 * 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [unityLoaded, avatarLoaded]);

  const trackHandler = useCallback(({ track, streams, participant }) => {
    console.log('Track handler:', track, streams, participant);
    if (track.kind === 'video' && videoRef.current) {
      const stream = streams[0];
      if (stream) {
        videoRef.current.srcObject = stream;
      } else {
        videoRef.current.srcObject = new MediaStream([track.mediaStreamTrack]);
      }
    }
    if (track.kind === 'audio') {
      let audioElement = document.getElementById('remote-audio');
      if (!audioElement) {
        audioElement = document.createElement('audio');
        audioElement.id = 'remote-audio';
        audioElement.autoplay = true;
        document.body.appendChild(audioElement);
      }
      const stream = streams[0];

      if (stream) {
        audioElement.srcObject = stream;
      } else {
        audioElement.srcObject = new MediaStream([track.mediaStreamTrack]);
      }
      setupAudioAnalysis(track.mediaStreamTrack);
    }
  }, []);

  const setupAudioAnalysis = (mediaTrack) => {
    if (!mediaTrack) return;
    try {
      const audioContext = new AudioContext();
      const mediaStream = new MediaStream([mediaTrack]);
      const source = audioContext.createMediaStreamSource(mediaStream);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);

      // Tell Unity to grab audio from the browser's audiocontext
      window.SpeechAudioSource = source;
      window.SpeechAudioContext = audioContext;
      unityInstance.current.SendMessage("WebGLAudioBridge", "OnSetAudioContext");

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      let spoken = false;

      function detectAudio() {
        analyser.getByteFrequencyData(dataArray);
        const hasAudio = dataArray.some((value) => value > 0);
        if (hasAudio) {
          if (unityInstance.current) {
            unityInstance.current.SendMessage("AvatarManager", "OnSpeech", dataArray.toString());
          }
          spoken = true;
        } else if (spoken) {
          if (unityInstance.current) {
            unityInstance.current.SendMessage("AvatarManager", "OnStopSpeech");
          }
          spoken = false;
        }
        requestAnimationFrame(detectAudio);
      }
      detectAudio();
    } catch (error) {
      console.error("Error setting up audio analysis:", error);
    }
  };

  // initialize unity automatically
  const initializeUnity = async (avatarName = 'Default') => {
    try {
      console.log("==> Unity initialization started");
      console.log("==> Canvas reference:", canvasRef.current);

      if (!window.createUnityInstance) {
        console.error("createUnityInstance not available!");
        setError("Unity loader not available. Please reload the page.");
        return;
      }

      if (!canvasRef.current) {
        console.error("Canvas reference is null!");
        setError("Canvas element not found. Please reload the page.");
        return;
      }

      // clear any previous error
      setError(null);
      setIsLoading(true);

      const instance = await window.createUnityInstance(canvasRef.current, {
        dataUrl: "/avatar/Build/GuruNowBuild.data.unityweb",
        frameworkUrl: "/avatar/Build/GuruNowBuild.framework.js.unityweb",
        codeUrl: "/avatar/Build/GuruNowBuild.wasm.unityweb",
        streamingAssetsUrl: "/avatar/StreamingAssets",
        companyName: "GuruNow",
        productName: "GuruNow AI",
        productVersion: "0.1",
        matchWebGLToCanvasSize: false,
      });

      console.log("==> Unity instance created successfully");
      unityInstance.current = instance;
      window.unityInstance = instance;

      const fallbackAvatarName = scenarioData?.avatar_persona?.avatar_model || avatarName || 'Default';
      const sceneName = scenarioData?.scene_name || "GuruNow Simulation";

      console.log(`==> Loading Unity scene: ${sceneName}/${fallbackAvatarName}`);
      unityInstance.current.SendMessage("SceneLoader", "LoadScene", String(sceneName + "/" + fallbackAvatarName));

      if (media && !isAudio) {
        media.setupLocalVideo(videoRef);
      }

      setIsLoading(false);
      setUnityLoaded(true);
      console.log("==> Unity initialized successfully - waiting for avatar to load");

      // webcam debug
      if (media && !isAudio) {
        console.log("==> Setting up local video with mediaManager");
        media.setupLocalVideo(videoRef);
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            console.log("==> Webcam access granted:", stream);
            if (videoRef.current) {
              console.log("==> Directly setting video source");
              videoRef.current.srcObject = stream;
            }
          })
          .catch(err => console.error("==> Webcam access error:", err));
      }

    } catch (error) {
      console.error('Failed to initialize Unity:', error);
      setError('Unity initialization failed: ' + error.message);
      setIsLoading(false);
    }
  };

  const manualConnect = () => {
    if (!unityLoaded) {
      setSnackbarMessage("Unity not loaded yet. Please wait for Unity to initialize first.");
      return;
    }

    console.log("==> Manually triggering LiveKit connection");
    setAvatarLoaded(true);
  };

  const connectToLiveKit = async () => {
    if (!liveKitManager || !isAudio) {
      if (isConnecting || !effectiveScenarioId) {
        console.log("==> Cannot connect to LiveKit");
        return;
      }
    }

    setIsConnecting(true);
    setError(null);

    console.log("==> Connecting to LiveKit with scenario ID:", effectiveScenarioId);

    try {
      const requestBody = { 'scenario_id': effectiveScenarioId };
      const start_simulation_endpoint = scenarioData?.external_scenario ? 'start_simulation_external' : 'start_simulation'

      const response = await fetch(`${brand.api_base_url}/${start_simulation_endpoint}`, {
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API error response:", errorText);
        throw new Error(`Failed to fetch token: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (!data['token']) {
        throw new Error("No token or URL received from server");
      }

      setSimulationId(data.id);
      setRoomName(data.id || "LiveKit Room");



      await liveKitManager.connect(data['livekit_url'], { token: data['token'] });
      setIsConnected(true);
      console.log("==> Connected to LiveKit successfully");

      // enable audio when connected
      await handleEnableAudio();

      if (media && !isAudio) {
        console.log("==> Setting up webcam");
        media.setupLocalVideo(videoRef);

        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            console.log("==> Webcam access granted after connection:", stream);
            if (videoRef.current) {
              console.log("==> Setting video source directly after connection");
              videoRef.current.srcObject = stream;
            }
          })
          .catch(err => console.error("==> Webcam access error after connection:", err));
      }

      if (data.avatar_name && unityInstance.current) {
        const sceneName = scenarioData?.scene_name || "GuruNow Simulation";
        unityInstance.current.SendMessage("SceneLoader", "LoadScene", String(sceneName + "/" + data.avatar_name));
      }

    } catch (error) {
      console.error("LiveKit connection error:", error);
      setError(error.message);
      setSnackbarMessage('Connection to LiveKit failed: ' + error.message);
    } finally {
      setIsConnecting(false);
    }
  };

  const handleDisconnect = () => {
    if (liveKitManager) {
      liveKitManager.cleanup();
      setIsConnected(false);
      setAudioEnabled(false);
    }
  };

  const handleEndSimulation = async () => {
    if (!simulationId) {
      setSnackbarMessage('No active simulation to end');
      return;
    }

    const end_simulation_endpoint = scenarioData?.external_scenario
      ? 'rtc_end_external_simulation?'
      : 'rtc_end_simulation';

    try {
      const response = await fetch(`${brand.api_base_url}/${end_simulation_endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'simulation_id': simulationId
        })
      });

      handleDisconnect();
      setSnackbarMessage('Simulation ended successfully');
      setSimulationId(null);

      // clean up Unity
      if (unityInstance.current) {
        try {
          unityInstance.current.Quit();
          unityInstance.current = null;
        } catch (e) {
          console.warn('Error cleaning up Unity instance:', e);
        }
      }

      if (scenarioData?.external_scenario) {
        window.location = '/externalscenario/' + scenarioData.id;
      } else {
        window.location = '/dashboard/simulations';
      }

    } catch (error) {
      console.error("Error ending simulation:", error);
      setSnackbarMessage(`Failed to end simulation: ${error.message}`);
    }
  };

  const handleEnableAudio = async () => {
    if (liveKitManager) {
      try {
        await liveKitManager.setMicrophoneEnabled(true);
        setAudioEnabled(true);
      } catch (error) {
        console.error('Error enabling microphone:', error);
        setError('Failed to enable audio: ' + error.message);
      }
    }
  };

  const handleTranscript = () => {
    setOpen(true);
    setTimeout(() => {
      transcriptRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);
    if (!open) {
      setTimeout(() => {
        transcriptRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    videoRef.current.startX = event.clientX - videoPosition.x;
    videoRef.current.startY = event.clientY - videoPosition.y;
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const newX = event.clientX - videoRef.current.startX;
      const newY = event.clientY - videoRef.current.startY;
      setVideoPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Snackbar
        open={snackbarMessage ? true : false}
        autoHideDuration={5000}
        onClose={() => { setSnackbarMessage(''); }}
        message={snackbarMessage}
      />

      {/* main container */}
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          width: "960px",
          height: "600px",
          borderRadius: 2,
          backgroundColor: !isAudio ? "#231F20" : "#d8d8d8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {/* unity canvas */}
        <canvas
          ref={canvasRef}
          id="unity-canvas"
          width={960}
          height={600}
          tabIndex={-1}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0
          }}
        />

        {isAudio ? (
          <></>
        ) : (
          <video
            ref={videoRef}
            id="video"
            autoPlay
            playsInline
            muted
            style={{
              position: "absolute",
              top: videoPosition.y,
              left: videoPosition.x,
              width: "25%",
              cursor: isDragging ? "grabbing" : "grab",
              border: "2px solid white",
              borderRadius: "4px",
              backgroundColor: "black",
              zIndex: 25,
              display: isConnected && !isLoading && !isConnecting ? "block" : "none",
            }}
            onMouseDown={handleMouseDown}
          />
        )}



        {/* loading overlay */}
        {isAudio ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 10,
            }}
          >
            <Typography variant="h5" color="black" mb={2}>
              {!isSpeaking ? "Connecting to call..." : "Audio call connected"}
            </Typography>

            {isSpeaking && (
              <Avatar
                src={imgSrc}
                alt="Call Image"
                onError={() => setImgSrc(null)}
                sx={{
                  height: 200,
                  width: 200,
                  backgroundColor: "white",
                  color: 'grey',
                }}
              />
            )}
          </Box>
        ) : (
          (isLoading || isConnecting || !isConnected) && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(35, 31, 32, 0.85)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 20,
              }}
            >
              <CircularProgress size={60} sx={{ color: "#ffffff" }} />
              <Typography variant="h5" mt={3} color="white">
                {isConnecting
                  ? "Connecting to simulation..."
                  : isLoading
                    ? "Loading the environment..."
                    : unityLoaded && !avatarLoaded
                      ? "Waiting for call to connect..."
                      : "Completing final touches..."}
              </Typography>

              {/* {unityLoaded && !avatarLoaded && !isConnecting && (
        <>
          <Typography variant="body2" mt={2} color="grey.400">
            Waiting for avatar initialization to complete
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={manualConnect}
            sx={{ mt: 3 }}
          >
            Connect Manually
          </Button>
        </>
      )} */}
            </Box>
          )
        )}

        {(isAudio || (isConnected && !isLoading && !isConnecting)) && (
          <Box
            sx={{
              position: "absolute",
              bottom: '1rem',
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              margin: '0 2rem',
              borderRadius: 20,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 100,
              color: "white",
            }}
          >
            <IconButton
              onClick={handleTranscript}
              sx={{
                color: "white",
                '&:hover': {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                marginLeft: 2,
              }}
            >
              <ArticleIcon />
            </IconButton>

            <Button
              variant="contained"
              color="error"
              onClick={handleEndSimulation}
              sx={{
                marginRight: 2,
                backgroundColor: "#d82c21",
                '&:hover': {
                  backgroundColor: "#b71c1c",
                },
              }}
            >
              End Simulation
            </Button>
          </Box>
        )}
      </Paper>

      {(isAudio || (isConnected && !isLoading && !isConnecting)) && (
        <Box sx={{ width: "100%", maxWidth: "960px", marginTop: 2 }}>
          <Button
            onClick={handleToggle}
            variant="contained"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              textTransform: "none",
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            <Typography variant="h6" color="white">Transcript</Typography>
            <ExpandMoreIcon
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Button>

          <Collapse in={open}>
            <Box
              ref={transcriptRef}
              sx={{
                border: "1px solid #ccc",
                borderRadius: 1,
                padding: 1,
                height: "200px",
                overflowY: "auto",
                backgroundColor: "white",
              }}
              dangerouslySetInnerHTML={{ __html: currentMessage }}
            />
          </Collapse>
        </Box>
      )}

      {error && (
        <Box sx={{ mt: 2, color: "error.main" }}>
          <Typography variant="body1">Error: {error}</Typography>
        </Box>
      )}

    </Box>
  );
};

export default CallScreen;
