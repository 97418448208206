import React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    Button

} from '@mui/material';

import heropic from '../assets/VR_CutOut.png'

import { LandingLayout } from '../components/complex/LandingLayout';

function LandingPage() {

    const navigate = useNavigate();

    return (
        <LandingLayout topnavminimal={true}>
            <Grid container>
                <Grid item xs={4} sx={{ textAlign: 'center', p: 1, ml: 4 }}>
                    <Typography variant="h3">EMPOWERING LEADERS THROUGH REAL-WORLD PRACTICE</Typography>
                    <Typography variant="h5">
                        Get ready to enhance your leadership skills with our innovative interactive avatar. Practice difficult conversations and build your confidence in handling real-world challenges effectively.
                    </Typography>
                    <Button size="large" variant="contained" sx={{ mt: 2 }} href="/dashboard/home">Start practicing</Button>
                </Grid>
                <Grid item xs={8}>

                </Grid>
            </Grid>
            <Box component="img" src={heropic} sx={{ height: '100%', display: { xs: 'none', md: 'block' }, position: 'absolute', right: 0, bottom: 0 }} />
        </LandingLayout>
    );
}

export default LandingPage;