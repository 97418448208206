import * as React from 'react';
import { Link as RouterLink, useParams, Navigate } from "react-router-dom";
import { Typography, Box, Link, Paper, Stack, Button } from '@mui/material';
import { BrandContext } from '../App';
import { LandingLayout } from '../components/complex/LandingLayout';
import heropic from '../assets/man_with_laptop.png'

export function LanderPage(props) {
    const { brand } = React.useContext(BrandContext);
    const { pagename } = useParams();

    const content = {
        'expert': (
            <React.Fragment>
                <Typography variant="h3" sx={{ mb: 3, textAlign: 'center' }}>Welcome to {brand.name}</Typography>
                <Typography variant="h5" sx={{ m: 3, textAlign: 'center' }}>The #1 platform for the world's leading companies to find {brand.profile_alias_plural} in {brand.skills.slice(0, 3).map((skill, index) => (<Box sx={{ display: 'inline' }} key={index}>{skill}, </Box>))} and more.</Typography>
                <Paper sx={{ p: 3, m: 1, mt: 3 }}>
                    <Stack direction="row">
                        <Box>
                            <Typography variant="h4" sx={{ mb: 3 }}>How does it work?</Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>1. Create your <RouterLink to="/signup/expert">free {brand.profile_alias} account</RouterLink> and tell us about your skills, experience, and desired hourly fee.</Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>2. Once your profile is live, companies can offer you projects.</Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>3. You can choose which project to join, and get paid for the hours you work.</Typography>
                            <Button variant="contained" href="/signup/expert" color="secondary">Create your {brand.profile_alias} account now</Button>
                        </Box>
                        <Box component="img" src={heropic} sx={{ height: '400px', display: { xs: 'none', xl: 'block' } }} />
                    </Stack>
                </Paper>
                <Paper sx={{ p: 3, m: 1, mt: 3 }}>
                    <Stack direction="row">
                        <Box>
                            <Typography variant="h4" sx={{ mb: 3 }}>Why {brand.name}?</Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>
                                <ul>
                                    <li>It's FREE and easy to <RouterLink to="/signup/expert">join</RouterLink> - it only takes 2 minutes</li>
                                    <li>Get paid weekly or monthly for the hours you have worked</li>
                                    <li>Showcase your skills and certifications to hundreds of companies looking for {brand.profile_alias_plural}</li>
                                </ul>
                            </Typography>

                        </Box>
                    </Stack>
                </Paper>
            </React.Fragment>
        ),
        'about-us': (
            <React.Fragment>
                <Typography variant="h3" sx={{ mb: 3 }}>About us</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{brand.name} is a global marketplace for companies to find the best experts:</Typography>
                {brand.skills.slice(0, 20).map((skill, index) => (
                    <>
                        <li><Link href={`/skill/${skill}`} color="inherit">{`${skill}`}</Link></li>
                    </>
                ))}
            </React.Fragment>
        ),
    };

    return (
        <LandingLayout>
            <Box sx={{ width: { xs: 1, md: 0.6 }, m: 1, mt: 5, marginLeft: 'auto', marginRight: 'auto', color: 'white' }}>
                {content[pagename] || <Navigate to="/404" replace={true} />}
            </Box>
        </LandingLayout>
    );
}
