import React, { useState, useEffect } from 'react';
import { AudioVisualizer } from '../components/AudioVisualizer';

const TestAudioVisualizer = () => {
  const [audioTrack, setAudioTrack] = useState(null);
  const [error, setError] = useState(null);

  const startAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioTrack(stream.getAudioTracks()[0]);
      setError(null);
    } catch (err) {
      setError('Microphone access denied: ' + err.message);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <button
        onClick={startAudio}
        style={{
          padding: '10px 20px',
          marginBottom: '20px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Start Microphone
      </button>

      {error && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </div>
      )}

      <div style={{
        width: '500px',
        height: '300px',
        position: 'relative',
        backgroundColor: '#231F20'
      }}>
        {audioTrack && <AudioVisualizer audioTrack={audioTrack} />}
      </div>
    </div>
  );
};

export default TestAudioVisualizer;