import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Alert,
    Snackbar,

} from '@mui/material';
import { BrandContext } from '../../App';

import GroupIcon from '@mui/icons-material/Group';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';

import Moment from 'moment';

import ReactPlayer from 'react-player'
import { CallScreen } from '../../components/complex/CallScreen';
import { isArrowKeys } from '@mui/x-data-grid/utils/keyboardUtils';

export function ExternalScenario() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [simulationId, setSimulationId] = React.useState(null);
    const [scenarioData, setScenarioData] = React.useState(null);
    const [simulationHistory, setSimulationHistory] = React.useState(null);
    const [simulationLineChartData, setSimulationLineChartData] = React.useState(null);
    const [simulationCallURL, setSimulationCallURL] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [popupBlocked, setPopupBlocked] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [showCallScreen, setShowCallScreen] = React.useState(true);
    const [isAudio, setIsAudio] = React.useState(false);

    const [searchParams] = useSearchParams();

    const { scenario_id } = useParams();
    const navigate = useNavigate();

    Moment.locale('en');

    function startScenario({ audio }) {
        setLoading(true);
        setIsAudio(audio);

        fetch(`${brand.api_base_url}/start_simulation_external`, {
            method: 'POST',
            body: JSON.stringify({ 'scenario_id': scenario_id })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['id']) {
                    setSimulationCallURL(data['webrtc_url']);
                    setSimulationId(data['id']);
                    setLoading(false);
                } else {
                    setSnackbarMessage('Starting simulation failed');
                    setLoading(false);
                }
                console.log(data);
            }).then()
            .catch((err) => {
                setSnackbarMessage('Starting simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        document.title = `${scenarioData?.name ? scenarioData.name : "GuruNow Simulation"}`;
    }, [scenarioData]);

    React.useEffect(() => {
        fetch(`${brand.api_base_url}/external_scenario?scenario_id=${scenario_id}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setScenarioData(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });

    }, [authToken, scenario_id]);

    function endSimulation() {
        setShowCallScreen(false);
        fetch(`${brand.api_base_url}/rtc_end_external_simulation`, {
            method: 'POST',
            body: JSON.stringify({ 'simulation_id': simulationId })
        })
            .then((response) => response.json())
            .then((data) => {
                if (scenarioData.has_scoring_engine) {
                    window.location.href = '/externalscenario_debrief/' + simulationId;
                } else {
                    window.location.href = '/externalscenario/' + scenarioData.id;
                }
            }).then()
            .catch((err) => {
                setSnackbarMessage('Ending simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }


    return (
        <React.Fragment>
            <Snackbar
                open={snackbarMessage ? true : false}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            {scenarioData != null && (
                <>
                    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        {simulationId == null ? (
                            <>

                                <Paper sx={{
                                    m: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                    p: 1
                                }}>
                                    {scenarioData.briefing_video ? (
                                        <>
                                            <ReactPlayer
                                                url={scenarioData.briefing_video}
                                                controls={true}
                                                width='100%'
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="h4" sx={{ fontWeight: 'bold', m: 2 }}>{scenarioData.name}</Typography>
                                        </>
                                    )}
                                    {searchParams.get('blocked') == 'true' && (<Alert severity="error" sx={{ m: 2, p: 2 }}>Please allow popup windows to run this simulation.</Alert>)}
                                    <Paper sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'center', alignContent: 'center', width: '55vw' }}>
                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{scenarioData.long_description}</Typography>
                                    </Paper>
                                    <Box>
                                        <Button onClick={() => startScenario({ audio: false })} variant="contained" size="large" sx={{ m: 2, p: '20px 75px', borderRadius: '50px' }} startIcon={<GroupIcon />}>Start Call</Button>
                                        <Button onClick={() => startScenario({ audio: true })} variant="contained" size="large" sx={{ m: 2, p: '20px 75px', borderRadius: '50px', backgroundColor: 'purple' }} startIcon={<VolumeUpRoundedIcon />}>Audio Only Call</Button>
                                    </Box>
                                </Paper>

                            </>
                        ) : (
                            <>
                                {showCallScreen ? (
                                    <CallScreen
                                        url={simulationCallURL}
                                        onDisconnect={endSimulation}
                                        scenarioData={scenarioData}
                                        isAudio={isAudio}
                                    />
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() => setShowCallScreen(true)}
                                    >
                                        Start Call
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </>
            )}


        </React.Fragment>
    );
}