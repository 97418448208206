import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Card,
    CardMedia,
    CardContent,
    CardActions
 } from '@mui/material';
import { BrandContext } from '../../App';

import { Chart as ChartJS } from 'chart.js/auto'
import { Line as LineGraph } from 'react-chartjs-2';

export default function DashboardHome() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [sites, setSites] = React.useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = `${user.first_name}'s Dashboard - ${brand.name}`;
    }, [user]);

    const eqData = {
        labels: ['May','June','July','August'],
        datasets: [
            {
              label: "Your GuruNow Performance Score™",
              data: [33, 39, 55, 85],
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            }
        ]
    };

    return (
        <React.Fragment>
            <Typography variant="h4">Welcome back, {user.first_name}!</Typography>
            <Box sx={{ display: 'flex', mt: 2 }}>
                <Card sx={{ maxWidth: 345, m: 1 }}>
                    <CardMedia
                        sx={{ height: 170 }}
                        image="https://albert-demo.rw.gd:8080/assets/TheThinker.jpg"
                        title=""
                    />
                    <CardContent>
                        <Typography variant="caption" color="text.secondary" sx={{ textTransform: 'uppercase'}}>
                            Next on learning path: Become a Super Influencer
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div">
                            The High Performer
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Continue your learning path by launching the next simulation now.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button size="large" variant="contained" href={`/dashboard/trainingprograms`}>View Guru Path</Button>
                        </Box>
                    </CardActions>
                </Card>
                <Box sx={{ flexGrow: 1 }}><LineGraph data={eqData} /></Box>

            </Box>

        </React.Fragment>
    );
}