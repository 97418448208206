import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Stack,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    CircularProgress,

} from '@mui/material';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import { BrandContext } from '../../App';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export function ExternalScenarioFeedback() {
    const { brand, user } = React.useContext(BrandContext);
    const [simulation, setSimulation] = React.useState(null);
    const [feedbackPolls, setFeedbackPolls] = React.useState(0);

    const maxFeedbackPolls = 10;
    const loadingMessages = [
        'Calculating impact',
        'Measuring EQ',
        'Simulating influence',
        'Analyzing intonations',
        'Processing tone',
        'Creating recommendations',
        'Comparing with peer group',
        'Evaluating impact score',
        'Calculating total score',
        'Creating report'
    ]

    const { simulation_id } = useParams();

    function updateSimulationData() {
        fetch(`${brand.api_base_url}/external_simulation?simulation_id=${simulation_id}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setSimulation(data);
                if (data.evaluation == null && feedbackPolls < maxFeedbackPolls) {
                    setTimeout(() => {
                        setFeedbackPolls(feedbackPolls + 1);
                    }, 3000);
                }
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    function setLabel(metricKey) {
        return metricKey.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
    }

    React.useEffect(() => {
        document.title = `Simulation Feedback - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSimulationData();

    }, [feedbackPolls]);

    return (
        <React.Fragment>
            {(simulation == null || simulation.evaluation == null || simulation.evaluation.total_score == 0) ? (
                <Box sx={{ textAlign: 'center', m: 4 }}>
                    {(feedbackPolls >= maxFeedbackPolls) ? (
                        <>
                            <Typography variant="h4">We've sent you your results via email. Thank you!</Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="h4" sx={{ pb: 2 }}>{loadingMessages[feedbackPolls]} ...</Typography>
                            <CircularProgress />
                        </>
                    )}

                </Box>
            ) : (
                <Box sx={{ textAlign: 'center', p: 3 }}>

                    <Typography variant="h4">Simulation Feedback</Typography>

                    <Paper sx={{ p: 2, m: 1 }}>
                        <Stack direction="row">
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="h4">{simulation.evaluation.total_score?.label || "Your GuruNow Performance Score&trade;"}</Typography>
                                <Gauge
                                    width={300}
                                    height={300}
                                    value={simulation.evaluation.total_score?.value}
                                    startAngle={-90}
                                    endAngle={90}
                                    sx={{
                                        [`& .${gaugeClasses.valueText}`]: {
                                            fontSize: 40,
                                            transform: 'translate(0px, 0px)',
                                        },
                                        [`& .${gaugeClasses.valueArc}`]: {
                                            fill: '#52b202',
                                        },
                                    }}
                                    text={
                                        ({ value, valueMax }) => (value / 10).toFixed(2)
                                    }
                                />
                            </Box>

                        </Stack>
                    </Paper>

                    {Object.entries(simulation.evaluation).map(([metricKey, metricData]) => {


                        if (metricKey === 'total_score' || metricKey === 'engine_name') {
                            return null;
                        }

                        return (
                            <Paper sx={{ p: 2, m: 1 }} key={metricKey}>
                                <Stack direction="row">
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="h4">{metricData?.label || setLabel(metricKey)}</Typography>
                                        <Gauge
                                            width={300}
                                            height={300}
                                            value={metricData.value}
                                            startAngle={-90}
                                            endAngle={90}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 40,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={({ value }) => (value / 10).toFixed(1)}
                                        />
                                    </Box>
                                    <Box sx={{ p: 4 }}>
                                        {metricData.positives && metricData.positives.length > 0 && (
                                            <>
                                                <Typography variant="h5">Here's what went well:</Typography>
                                                <List dense sx={{ width: '100%' }}>
                                                    {metricData.positives.map((item, index) => (
                                                        <ListItem key={`${metricKey}-positive-${index}`}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <ThumbUpAltIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                        {metricData.recommendations && metricData.recommendations.length > 0 && (
                                            <>
                                                <Typography variant="h5">
                                                    Improve your score with these recommendations:
                                                </Typography>
                                                <List dense sx={{ width: '100%' }}>
                                                    {metricData.recommendations.map((item, index) => (
                                                        <ListItem key={`${metricKey}-recommendation-${index}`}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <TrendingUpIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                    </Box>
                                </Stack>
                            </Paper>
                        );
                    })}

                </Box>
            )}


        </React.Fragment>
    );
}