export class UnityAudioAnalyzer {
    constructor(unityManager) {
        this.unityManager = unityManager;
        this.audioContext = null;
        this.analyser = null;
        this.analysisActive = false;
    }

    setupAnalysis(mediaTrack) {
        if (!mediaTrack || this.analysisActive) return false;

        try {
            this.audioContext = new AudioContext();
            const mediaStream = new MediaStream([mediaTrack]);
            const source = this.audioContext.createMediaStreamSource(mediaStream);
            this.analyser = this.audioContext.createAnalyser();
            source.connect(this.analyser);

            this.analyser.fftSize = 256;
            const bufferLength = this.analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            let spoken = false;
            this.analysisActive = true;

            const detectAudio = () => {
                if (!this.analysisActive) return;

                this.analyser.getByteFrequencyData(dataArray);

                // check for non-zero audio data
                const hasAudio = dataArray.some((value) => value > 30); // threshold to avoid background noise

                if (hasAudio) {
                    this.unityManager.onSpeech(dataArray.toString());
                    spoken = true;
                } else if (spoken) {
                    this.unityManager.onStopSpeech();
                    spoken = false;
                }

                requestAnimationFrame(detectAudio);
            };

            detectAudio();
            return true;
        } catch (error) {
            console.error("Error setting up audio analysis:", error);
            return false;
        }
    }

    stop() {
        this.analysisActive = false;

        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }

        this.analyser = null;
    }
}