
//Credits: https://javascript.plainenglish.io/mic-audio-visualizer-using-react-and-canvas-4e89905141ac

import React, { useEffect, useRef } from 'react';

export const AudioVisualizer = ({ audioTrack }) => {
  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    if (!audioTrack || !canvasRef.current) {
      console.log('No audio track or canvas:', { audioTrack: !!audioTrack, canvas: !!canvasRef.current });
      return;
    }

    console.log('Initializing audio visualizer');
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(new MediaStream([audioTrack]));

    analyser.fftSize = 256;
    analyser.minDecibels = -90;
    analyser.maxDecibels = -10;
    analyser.smoothingTimeConstant = 0.85;

    source.connect(analyser);
    analyserRef.current = analyser;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const data = new Uint8Array(analyser.frequencyBinCount);

    const draw = () => {
      const width = canvas.width;
      const height = canvas.height;

      analyser.getByteFrequencyData(data);

      // Clear canvas
      ctx.fillStyle = 'rgb(20, 20, 20)';
      ctx.fillRect(0, 0, width, height);

      // Draw frequency bars
      const barWidth = (width / data.length) * 2.5;
      let x = 0;

      for(let i = 0; i < data.length; i++) {
        const barHeight = (data[i] / 255) * height;

        // Create gradient color based on frequency
        const hue = (i / data.length) * 360;
        ctx.fillStyle = `hsl(${hue}, 100%, 50%)`;

        ctx.fillRect(x, height - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }

      // Log volume levels periodically
      if (Math.random() < 0.01) { // Log ~1% of frames
        const avgVolume = data.reduce((a, b) => a + b, 0) / data.length;
        console.log('Current volume:', avgVolume);
      }

      animationRef.current = requestAnimationFrame(draw);
    };

    console.log('Starting animation loop');
    draw();

    return () => {
      console.log('Cleaning up visualizer');
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      audioContext.close();
    };
  }, [audioTrack]);

  return (
    <canvas
      ref={canvasRef}
      width={300}
      height={100}
      style={{
        position: 'absolute',
        bottom: '80px',
        left: '20px',
        background: 'rgb(20, 20, 20)',
        borderRadius: '4px',
        zIndex: 100
      }}
    />
  );
};