export class MediaManager {
    constructor() {
        this.localStream = null;
        this.videoElement = null;
    }

    async setupLocalVideo(videoRef) {
        if (!videoRef?.current) return null;

        try {
            this.videoElement = videoRef.current;

            // Request video-only stream
            this.localStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });

            // Attach to video element
            this.videoElement.srcObject = this.localStream;
            return this.localStream;
        } catch (error) {
            console.error('Failed to initialize local video:', error);
            return null;
        }
    }

    cleanup() {
        if (this.localStream) {
            this.localStream.getTracks().forEach(track => {
                track.stop();
            });
            this.localStream = null;
        }

        if (this.videoElement && this.videoElement.srcObject) {
            this.videoElement.srcObject = null;
        }
    }
}