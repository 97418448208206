import React from 'react';
import {
    Typography,
} from '@mui/material';
import { BrandContext } from '../../App';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [settings, setSettings] = React.useState(null);

    function updateSettings() {
        fetch(`${brand.api_base_url}/settings`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSettings(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Settings - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSettings();

    },[authToken]);

    return (
        <React.Fragment>
            <Typography variant="h4">Settings</Typography>
        </React.Fragment>
    );
}