export class UnityManager {
    constructor(canvasRef) {
        this.canvasRef = canvasRef;
        this.unityInstance = null;
        this.eventListeners = new Map();
        this.isInitialized = false;
        this.avatarName = null;
    }

    async initialize(avatarName, sceneName = "GuruNow Simulation") {
        try {

            if (!window.createUnityInstance) {
                throw new Error('Unity loader error');
            }

            if (!this.canvasRef) {
                throw new Error('Canvas reference is null');
            }

            this.avatarName = avatarName;
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.unityInstance = await window.createUnityInstance(this.canvasRef, {
                dataUrl: "/avatar/Build/GuruNowBuild.data.unityweb",
                frameworkUrl: "/avatar/Build/GuruNowBuild.framework.js.unityweb",
                codeUrl: "/avatar/Build/GuruNowBuild.wasm.unityweb",
                streamingAssetsUrl: "/avatar/StreamingAssets",
                companyName: "GuruNow",
                productName: "GuruNow AI",
                productVersion: "0.1",
                matchWebGLToCanvasSize: false,
            });

            window.unityInstance = this.unityInstance;
            const formattedAvatarName = this.avatarName.charAt(0).toUpperCase() + this.avatarName.slice(1);
            this.loadAvatar(formattedAvatarName, sceneName);
            this.isInitialized = true;
            this.emitEvent('unityReady', { success: true });
            return this.unityInstance;

        } catch (error) {
            console.error('Failed to initialize Unity:', error);
            this.emitEvent('unityError', { error });
            throw error;
        }
    }

    loadAvatar(avatarName, sceneName) {
        if (!this.unityInstance) return false;

        try {
            this.unityInstance.SendMessage("SceneLoader", "LoadScene", `${sceneName}/${avatarName}`);
            this.emitEvent('avatarLoaded', { avatarName });
            return true;
        } catch (error) {
            console.error('Failed to load avatar:', error);
            this.emitEvent('unityError', { error });
            return false;
        }
    }

    setupAudioAnalysis(mediaTrack) {
        if (!mediaTrack) return null;
        try {

            const audioContext = new AudioContext();
            const mediaStream = new MediaStream([mediaTrack]);
            const source = audioContext.createMediaStreamSource(mediaStream);
            const analyser = audioContext.createAnalyser();
            source.connect(analyser);
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            let spoken = false;

            const detectAudio = () => {
                analyser.getByteFrequencyData(dataArray);
                const hasAudio = dataArray.some((value) => value > 0);
                if (hasAudio) {
                    this.onSpeech(dataArray.toString());
                    spoken = true;
                } else if (spoken) {
                    this.onStopSpeech();
                    spoken = false;
                }
                if (this.isInitialized) {
                    requestAnimationFrame(detectAudio);
                }
            };

            detectAudio();

            return {
                audioContext,
                analyser,
                cleanup: () => {
                    audioContext.close();
                }
            };

        } catch (error) {
            console.error("Error setting up audio analysis:", error);
            return null;
        }
    }

    onSpeech(data) {
        if (this.unityInstance) {
            this.unityInstance.SendMessage("AvatarManager", "OnSpeech", data);
            this.emitEvent('speechStart', { data });
        }
    }

    onStopSpeech() {
        if (this.unityInstance) {
            this.unityInstance.SendMessage("AvatarManager", "OnStopSpeech");
            this.emitEvent('speechStop', {});
        }
    }

    triggerAvatarAnimation(message) {
        if (this.unityInstance) {
            this.unityInstance.SendMessage("AvatarManager", "TriggerAvatarAnimation", message);
            this.emitEvent('animationTriggered', { message });
        }
    }

    avatarInitialized() {
        console.log("====> Avatar initialization complete, dispatching avatarLoaded event");
        this.dispatchEvent('avatarLoaded', {});
    }

    addEventListener(eventName, callback) {
        if (!this.eventListeners.has(eventName)) {
            this.eventListeners.set(eventName, []);
        }

        this.eventListeners.get(eventName).push(callback);
        return () => this.removeEventListener(eventName, callback);
    }

    removeEventListener(eventName, callback) {
        if (!this.eventListeners.has(eventName)) return;

        const listeners = this.eventListeners.get(eventName);
        this.eventListeners.set(
            eventName,
            listeners.filter(listener => listener !== callback)
        );
    }

    emitEvent(eventName, data) {
        if (!this.eventListeners.has(eventName)) return;
        this.eventListeners.get(eventName).forEach(callback => {
            try {
                callback(data);
            } catch (error) {
                console.error(`Error in ${eventName} event listener:`, error);
            }
        });
    }

    forceCleanup() {
        if (this.unityInstance) {
            this.unityInstance.SendMessage('GameManager', 'UnloadUnusedAssets');
            this.unityInstance.Quit();
            this.unityInstance = null;
        }

        window.unityInstance = null;
        this.isInitialized = false;
        this.eventListeners.clear();

        if (window.gc) {
            window.gc();
        }
    }

    cleanup() {
        if (this.unityInstance) {
            this.unityInstance.SendMessage('GameManager', 'CleanupMemory');
            this.unityInstance.Quit();
            this.unityInstance = null;
        }

        if (window.unityInstance) {
            window.unityInstance = null;
        }

        this.isInitialized = false;
        this.emitEvent('unityCleaned', {});
    }
}
